import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

//import { SideContentComponent } from './components/side-content/side-content.component';
import { HomePage } from './pages/home/home.page';
import { YajnaHyderabadProjectPage } from './pages/yajna/yajna-project/yajna-hyderabad-project.page';
import { YajnaProjectsPage } from './pages/yajna/yajna-project/yajna-projects.page';

const routes: Routes = [
  { path: '', component: HomePage, data: { title: 'Home' } },
  { path: 'home', component: HomePage, data: { title: 'Home' } }, 
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule),
    data: { title: 'Auth' }  
  },
  {
    path: 'yajna',
    loadChildren: () => import('./pages/yajna/yajna.module').then(m => m.YajnaModule)   
  },  
  {
    path: 'page',
    loadChildren: () => import('./pages/static-page/static.module').then(m => m.StaticModule)   
  },  
  {
    path: 'blog',
    loadChildren: () => import('./pages/blog/blog.module').then(m => m.BlogModule),
    data: { title: 'Blog' }  
  },
  {
    path: 'research',
    loadChildren: () => import('./pages/research/research.module').then(m => m.ResearchModule),
    data: { title: 'Research' }
  },
  {
    path: 'works',
    loadChildren: () => import('./pages/works/works.module').then(m => m.WorksModule),
    data: { title: 'Works' }
  },
  {
    path: 'auth/admin/web-pages',
    loadChildren: () => import('./pages/web-page/web-page.module').then(m => m.WebPageModule),
    data: { title: 'Admin' }  
  },
  {
    path: 'auth/admin/web-items',
    loadChildren: () => import('./pages/web-item/web-item.module').then(m => m.WebItemModule),
    data: { title: 'Admin' }  
  },
  {
    path: 'donate',
    loadChildren: () => import('./pages/donate/donate.module').then(m => m.DonateModule),
    data: { title: 'Donate' }  
  },
  {
    path: 'auth/admin/media',
    loadChildren: () => import('./pages/media/media.module').then(m => m.MediaModule),
    data: { title: 'Media' }  
  },
  { path: '*', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

