export enum MediaFolder { 
  Image = 1,
  EventPhoto = 2,
  Pdf = 3
}

export enum MediaFileType {
  Image = 1,
  Pdf = 2,
  Video = 3
}


export class MediaListInput {
  mediaFolder: string; //this can be media folder or photo folder
  currentPage: number;
  pageSize: number;
  //tags: string;
}

export class MediaFile {
  fileName: string;
  fileType: MediaFileType;
  folder: MediaFolder;
  photoFolder: string;
  fileSize: number;
  tags: string;
}

export class MediaDownloadInput {
  fileName: string;
  mediaFolder: MediaFolder;
  //tags: string;
}

export class MediaUploadInput {
  fileName: string;
  mediaFolder: MediaFolder; 
  tags: string;
}
