
import { PagedResult, BaseEntity, ImageIcons, Keywords } from './shared.models';

export enum ItemType {
  //Undefined = 0,
  //Cause = 1,
  Event = 2,
  Announcement = 3,
  Subscriber = 4,
  Video = 5,
  //Photo = 6,
  Donor = 7
}


export class Item extends BaseEntity {
  itemType: ItemType;
  active: boolean;
}

export class Announcement extends Item {
  constructor() {
    super();
    this.itemType = ItemType.Announcement;
  }

  purpose: string;
  images: ImageIcons;
  startDate?: Date;
  endDate?: Date;
}

export class Event extends Announcement {
  constructor() {
    super();
    this.itemType = ItemType.Event;
  }

  content: string;
  photos: string;
}

/*
export class Cause extends Event {
  constructor() {
    super();
    this.itemType = ItemType.Cause;
  }
  target: number;
  collectedSoFar: number;
}*/

export class Subscriber extends Item {
  constructor() {
    super();
    this.itemType = ItemType.Subscriber;
  }
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  state: string;
  countryCode: string;
  postalCode: string;
  details: string;
  optInNotifications: boolean;
}

export class Video extends Item {
  constructor() {
    super();
    this.itemType = ItemType.Video;
  }
  videoId: number;
  videoLink: string;
  summary: string;
  transcript: string;
  images: ImageIcons;
  videoTimeDuration: string;
  authors: string;
  publishDate?: Date;
  keywords: Keywords;
}

export class ItemGetCmdInput {
  itemId: string;
  key: string;
}

export class ItemGetCmdOutput {
  item: Item;
}

export class ItemSaveCmdInput {
  item: any;
}

export class ItemSaveCmdOutput {
  saved: boolean;
  itemId: string;
  errorMsg: string;
}

export class ItemListCmdInput {
  itemType: ItemType;
  active: boolean;
  currentPage: number;
  pageSize: number;
  includeItem: boolean;
}

export class ItemListDto {
  itemId: string;
  itemType: ItemType;
  key: string;
  active: boolean;
  createdOn?: Date;
  createdBy: string;
  updatedOn?: Date;
  updatedBy: string;
}

export class ItemListCmdOutput {
  items: PagedResult<ItemListDto>;
}

export class ItemExportListCmdInput {
  itemType: ItemType;
}

export class ItemExportListCmdOutput {
  items: any[];
}

export class ItemDeleteCmdInput {
  itemId: string;
}

export class ItemDeleteCmdOutput {
  deleted: boolean;
  errorMsg: string;
}
