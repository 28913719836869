import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router, ActivatedRoute, NavigationEnd} from '@angular/router';
import {Subscription} from 'rxjs';
import { Topnav } from '../../models';
import { filter, map } from 'rxjs/operators';

@Component({
    selector: 'app-topnav',
    templateUrl: './topnav.component.html',
    styleUrls: ['./topnav.component.scss']
})
export class TopnavComponent implements OnInit, OnDestroy {

 
  public navbarOpen = false;

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute) {
    }

    topNav: Topnav;
    private subscription: Subscription;

  ngOnInit() {    
      this.createTopNavItems();
      this.getSelectedMenu();
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
  }

  private getSelectedMenu() {
    
    this.router
      .events.pipe(
        filter(e => e instanceof NavigationEnd),
        map(() => {
          const child = this.activatedRoute.firstChild;
          //console.log('I am here');
          const sel = this.topNav.items.filter(x => x.title.indexOf(child.snapshot.data['title']) > 0);
         
          if (sel && sel.length > 0) {            
            sel[0].style ="active"; 
          }
          else {
            const home = this.topNav.items.filter(x => x.route === '/home');
            if (home && home.length > 0) {
              home[0].style = "active";              
            }
          }
        })
      );
  }

  private createTopNavItems() {

    this.topNav = {
      items: [
        {
          title: 'Home',
          icon: undefined,
          selected: false,
          style: "",
          route: '/home'
        },
        {
          title: 'About',
          icon: undefined,
          selected: false,
          style: "",
          route: '/about'
        },
        {
          title: 'Causes',
          icon: undefined,
          selected: false,
          style: "",
          route: '/cause'
        },
        {
          title: 'Research',
          icon: undefined,
          selected: false,
          style: "",
          route: '/research'
        },
        {
          title: 'Blog',
          icon: undefined,
          selected: false,
          style: "",
          route: '#',
          items: [
            {
              title: 'Posts',
              icon: undefined,
              selected: false,
              style: "",
              route: '/blog'
            },
            {
              title: 'Videos',
              icon: undefined,
              selected: false,
              style: "",
              route: '/video'
            },
            {
              title: 'Events',
              icon: undefined,
              selected: false,
              style: "",
              route: '/event'
            }
          ]
        }
      ]
    };

    
  }

  private isHomeMenu(): boolean {
    //console.log(this.router.url);
    if (this.router.url === '/home') {
      return true;
    }
    else {
      return false;
    }
  }

  public toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }
}

