import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-sitemap-page',
    templateUrl: './sitemap.page.html',
  styleUrls: ['./sitemap.page.scss']
})
export class SitemapPage implements OnInit {

  public pages: any[] = [];
  public blogPosts: any[] = [];
  public researchPosts: any[] = [];
  public presentations: any[] = [];
  public causes: any[] = [];
  public events: any[] = [];
  public englishVideos: any[] = [];
  public teluguVideos: any[] = [];

    constructor() {
    }    

  ngOnInit() {
   
    } 

  
}
