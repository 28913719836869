import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { LoginCmdOutput } from '../../models/auth.models';


@Component({
  selector: 'app-admin-menu',
  templateUrl: './admin-menu.component.html',
  styleUrls: ['./admin-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdminMenuComponent implements OnInit {

  user: LoginCmdOutput;

  constructor(protected route: ActivatedRoute,
    private auth: AuthService) {
  }

  ngOnInit() {
    this.user = this.auth.getUserFromStorage();
  }

 public openNav() {
  document.getElementById("adminSidebar").style.width = "250px";
  document.getElementById("main").style.marginLeft = "250px";
}

public closeNav() {
  document.getElementById("adminSidebar").style.width = "0";
  document.getElementById("main").style.marginLeft = "0";
}
}
