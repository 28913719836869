import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-feed-page',
    templateUrl: './feed.page.html',
    styleUrls: ['./feed.page.scss']
})
export class FeedPage implements OnInit {
    protected rss;
    protected atom;
    protected sitemap;

    constructor() {
    }

    ngOnInit() {
       
    }
}
