import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  ItemGetCmdInput, ItemGetCmdOutput, ItemSaveCmdInput, ItemSaveCmdOutput, ItemDeleteCmdOutput, ItemDeleteCmdInput, ItemListCmdOutput, ItemListCmdInput,
  ItemExportListCmdInput, ItemExportListCmdOutput
} from '../models/item.models';

@Injectable({
  providedIn: 'root'
})

export class ItemService {    
  currentUser = {};

  constructor(private httpClient: HttpClient) { }

  listItem(input: ItemListCmdInput): Observable<ItemListCmdOutput> {
    const params = new HttpParams();
    let qstring = "";
    for (const key of Object.keys(input)) {     
      params.append(key.toString(), input[key]);
      qstring += "/";
      qstring += input[key].toString();
    }

    const api = "ItemListApi" + qstring;
    //return this.httpClient.get<ItemListCmdOutput>("ItemListApi", { params: params });
    return this.httpClient.get<ItemListCmdOutput>(api);
  }

  exportItemList(input: ItemExportListCmdInput): Observable<ItemExportListCmdOutput> {
    const params = new HttpParams();
    let qstring = "";
    for (const key of Object.keys(input)) {
      params.append(key.toString(), input[key]);
      qstring += "/";
      qstring += input[key].toString();
    }

    const api = "ItemExportListApi" + qstring;
    //return this.httpClient.get<ItemListCmdOutput>("ItemListApi", { params: params });
    return this.httpClient.get<ItemExportListCmdOutput>(api);
  }

  getItem(input: ItemGetCmdInput): Observable<ItemGetCmdOutput> {

    const params = new HttpParams();
    let qstring = "";
    for (const key of Object.keys(input)) {
      params.append(key.toString(), input[key]);
      qstring += "/";
      qstring += input[key].toString();
    }

    const api = "ItemGetApi" + qstring;
   // return this.httpClient.get<ItemGetCmdOutput>('ItemGetApi', { params: params });
    return this.httpClient.get<ItemGetCmdOutput>(api);  
  }

  savetItem(input: ItemSaveCmdInput): Observable<ItemSaveCmdOutput> {
    return this.httpClient.post<ItemSaveCmdOutput>('ItemSaveApi', input);
  }

  delItem(input: ItemDeleteCmdInput): Observable<ItemDeleteCmdOutput> {
    return this.httpClient.post<ItemDeleteCmdOutput>('ItemDeleteApi', input);
  }
}
