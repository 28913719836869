import { Component, OnInit, AfterViewInit  } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, RoutesRecognized } from '@angular/router';
import { filter, map, mergeMap, skipWhile } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import * as $ from 'jquery';
import { LoginCmdOutput } from './models/auth.models';
import { AuthService } from './services/auth.service';
import { Properties } from 'azure-maps-control';
import { Subscription } from 'rxjs';

//multiple router-outlets
//https://www.techiediaries.com/angular-router-multiple-outlets/

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Vedas World';
  user: LoginCmdOutput;
  
  constructor(
            private router: Router,
            private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private auth: AuthService) {

    console.clear();    
  }

  ngOnInit() {
    this.subscribeToDocTitle();
  }

  private subscribeToDocTitle() {
    const appTitle = this.titleService.getTitle();
    this.router
      .events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child.firstChild) {
            child = child.firstChild;
          }
          if (child.snapshot.data['title']) {
            //console.log('child title: ' + child.snapshot.data['title']);
            return child.snapshot.data['title'] + " - " + this.title;
          }
          return appTitle + " - " + this.title;
        })
    ).subscribe((ttl: string) => {
        //console.log(ttl);
        this.titleService.setTitle(ttl);
      });
  }

  checkUser() {
    const isLoggedIn = this.auth.isLoggedIn();
    this.user = this.auth.getUserFromStorage();
    if (isLoggedIn === false || !this.user) {
      return false;
    }

    return true;
  }

  showHeaderFooter() {
    //console.log(this.router.url);
    if (this.router.url === '/auth/login') {
      return false;
    }
    return true;
  }

  showAdminMenu() {
    //console.log(this.router.url);
    if (this.router.url.startsWith('/auth/admin')) {     
      return true;
    }
    return false;
  }

  /*
  setDocTitle(title: string) {
    console.log('current title:::::' + this.titleService.getTitle());
    this.titleService.setTitle(title);
  }
  */

}
