import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { LoginCmdOutput } from '../../models/auth.models';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  fontWeight = 300;
  fontSize = 14;
  user: LoginCmdOutput;
  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private auth: AuthService) {
  }

  checkUser() {
    const isLoggedIn = this.auth.isLoggedIn();
    this.user = this.auth.getUserFromStorage();
    if (isLoggedIn === false || !this.user) {
      return false;
    }

    return true;
  }

  increaseFont() {
    this.fontWeight = this.fontWeight + 10;
    this.fontSize = this.fontSize + 2;
    const container = document.getElementsByClassName('single-content')[0] as HTMLElement;
    if (container) {
      container.style.fontSize = this.fontSize.toString() + " !important";
      console.log(this.fontSize);
    }
  }

  decreaseFont() {
    this.fontWeight = this.fontWeight - 10;
    this.fontSize = this.fontSize - 2;
    const container = document.getElementById('root-id');
    if (container) {
      container.style.fontWeight = this.fontWeight.toString() + " !important";;
      console.log(this.fontWeight);
    }
  }
 }
