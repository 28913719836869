import {Component, OnInit} from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { AuthService } from '../../services/auth.service';
import { PageService } from '../../services/page.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { ItemService } from '../../services/item.service';
import { Subscription } from 'rxjs';
import { PageListCmdInput, PageListDto, PageType, PageGetCmdInput, Page } from '../../models/page.models';
import { PagedResult, trimS, getYouTubeVideoImageUrl, getYouTubeVideoImageName, getSummaryImageName, getSummaryImageAsBackgroundUrl } from '../../models/shared.models';
import { ItemListDto, ItemListCmdInput, ItemType, Event, Announcement, Video } from '../../models/item.models';

import { SwiperOptions } from 'swiper';
import { MediaFolder } from 'src/app/models/media.models';

@Component({
    selector: 'app-home-page',
    templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],

})
export class HomePage implements OnInit {

  recentPostsSub$: Subscription;
  recentItemsSub$: Subscription;
  pageGetSub$: Subscription;

  errorMsg: string;

  blogPost: PageListDto;
  blogList: PagedResult<PageListDto>;
  researchList: PagedResult<PageListDto>;

  eventList: PagedResult<ItemListDto>;
  videoList: PagedResult<ItemListDto>;
  announcements: PagedResult<ItemListDto>;

  donate: string;

  config: SwiperOptions = {
    pagination: { el: '.swiper-pagination', clickable: true },
    autoHeight: true,
    allowTouchMove: true,
    autoplay: {
      delay: 6000,
      disableOnInteraction: true
    },
    breakpoints: {
      1024: {
        slidesPerView: 1
      },
      500: {
        slidesPerView: 1
      },
      400: {
        slidesPerView: 1
      },
      300: {
        slidesPerView: 1
      }
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    loop: true
  };

  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private pageService: PageService,
    private itemService: ItemService,
    private logger: NGXLogger) {
    }

  ngOnInit() {
    this.getRecentPosts(PageType.Blog);
    this.getRecentPosts(PageType.Research);

    this.getRecentItems(ItemType.Event);
    this.getRecentItems(ItemType.Video);
    this.getRecentItems(ItemType.Announcement);
    this.getDonatePage("home-donate");
    }

  getRecentPosts(pageType: number) {

    const input = new PageListCmdInput();
    //input parameters should be in this order
    input.pageType = pageType;
    input.draft = false;
    input.currentPage = 1;
    input.pageSize = 5;
    input.forEdit = false;

    //console.log(input);
    this.recentPostsSub$ = this.pageService.listPage(input)
      .subscribe((res) => {
        //console.log(res);
        if (res) {
          //this.logger.log(res.pages);
          if (pageType === PageType.Blog) {
            this.blogList = res.pages;
            if (this.blogList?.results["$values"].length > 0) {
              this.blogPost = this.blogList?.results["$values"][0];
              //console.log(this.blogPost);
            }           
          } else {
            this.researchList = res.pages;
            //console.log(this.researchList);
          }                  
        }
        else {
          this.errorMsg = "Unable to get the list. Please try again";
        }
      });
  }

  getStyleForFirstBlog(index) {   
    if (index === 0) {     
      return "bg-light";
    }
  }

  getRecentItems(itemType: number) {

    const input = new ItemListCmdInput();
    //input parameters should be in this order
    input.itemType = itemType;
    input.currentPage = 1;
    input.pageSize = 6;
    input.includeItem = true;

    //console.log(input);
    this.recentItemsSub$ = this.itemService.listItem(input)
      .subscribe((res) => {
        //console.log(res);
        if (res) {
          //this.logger.log(res.pages);
          if (itemType === ItemType.Event) {
            this.eventList = res.items;
          } else if (itemType === ItemType.Video) {
            this.videoList = res.items;
          } else if (itemType === ItemType.Announcement) {
            this.announcements = res.items;
          }

        }
        else {
          this.errorMsg = "Unable to get the list. Please try again";
        }
      });
  }

  getDonatePage(key: string) {
    if (key.trim() !== "") {
      const input = new PageGetCmdInput();
      input.pageId = trimS(key);
      input.draft = false;
     
      this.pageGetSub$ = this.pageService.getPage(input)
        .subscribe((res) => {
          //console.log(res);
          if (res && res.page) {
            this.donate = res.page.content;
            //console.log(this.post);           
          }
        },
          error => {
            this.logger.error(error);
            this.errorMsg = error;
          });
    }
  }

  getBlogImageName(post: PageListDto) {
    return getSummaryImageName(post.summaryImage);    
  }

  getBlogImageUrl(post: PageListDto) {

    return getSummaryImageAsBackgroundUrl(post.summaryImage);
  }

  getVideoImageUrl(videoLink: string) {    

    return getYouTubeVideoImageUrl(videoLink);
  }

  getVideoImageName(videoLink: string) {

    return getYouTubeVideoImageName(videoLink);
  }

  getEventImageUrl(event: Event) {
    return getSummaryImageAsBackgroundUrl(event.images?.summaryImage);
  }

  getEventImageName(event: Event) {
    return getSummaryImageName(event.images?.summaryImage, MediaFolder.EventPhoto);   
  }

  getAnnouncementImageUrl(announcement: Announcement) {
    return getSummaryImageAsBackgroundUrl(announcement.images?.summaryImage);    
  }

  getAnnouncementImageName(announcement: Announcement) {
    return getSummaryImageName(announcement.images?.summaryImage);      
  }

  ngOnDestroy(): void {
    if (this.recentPostsSub$) {
      this.recentPostsSub$.unsubscribe();
    }

    if (this.recentItemsSub$) {
      this.recentItemsSub$.unsubscribe();
    }

    if (this.pageGetSub$) {
      this.pageGetSub$.unsubscribe();
    }
    
  }
}
