import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PageListCmdInput, PageListCmdOutput, PageGetCmdInput, PageGetCmdOutput, PageSaveCmdInput, PageSaveCmdOutput, PageDeleteCmdInput, PageDeleteCmdOutput } from '../models/page.models';

@Injectable({
  providedIn: 'root'
})

export class PageService {    
  currentUser = {};

  constructor(private httpClient: HttpClient) { }

  listPage(input: PageListCmdInput): Observable<PageListCmdOutput> {
    const params = new HttpParams();
    let qstring = "";
    for (const key of Object.keys(input)) {
      params.append(key.toString(), input[key]);
      qstring += "/";
      qstring += input[key].toString();
    }

    const api = "PageListApi" + qstring;
    //return this.httpClient.get<PageListCmdOutput>('PageListApi', { params: params });
    return this.httpClient.get<PageListCmdOutput>(api);
  }

  getPage(input: PageGetCmdInput): Observable<PageGetCmdOutput> {

    const params = new HttpParams();
    let qstring = "";
    for (const key of Object.keys(input)) {
      params.append(key.toString(), input[key]);
      qstring += "/";
      qstring += input[key].toString();
    }

    const api = "PageGetApi" + qstring;
    // return this.httpClient.get<ItemGetCmdOutput>('ItemGetApi', { params: params });
    return this.httpClient.get<PageGetCmdOutput>(api);  
    //return this.httpClient.get<PageGetCmdOutput>('PageGetApi', { params: params });      
  }

  savePage(input: PageSaveCmdInput): Observable<PageSaveCmdOutput> {
    return this.httpClient.post<PageSaveCmdOutput>('PageSaveApi', input);
  }

  delPage(input: PageDeleteCmdInput): Observable<PageDeleteCmdOutput> {
    return this.httpClient.post<PageDeleteCmdOutput>('PageDeleteApi', input);
  }
}
