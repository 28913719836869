import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import {AppRoutingModule} from './app-routing.module';
import { AppComponent } from './app.component';

import { EmbedVideo } from 'ngx-embed-video';

import { HomePage } from './pages/home/home.page';
import { SitemapPage } from './pages/sitemap/sitemap.page';

import {FeedPage} from './pages/feed/feed.page';
import { HeaderComponent } from './components/header/header.component';
import { TopnavComponent } from './components/topnav/topnav.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { FooterComponent } from './components/footer/footer.component';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { HttpConfigInterceptor } from './interceptors/http.interceptor';
import { HttpErrorInterceptor } from './interceptors/eror.interceptor';
import { AdminMenuComponent } from './components/admin-menu/admin-menu.component';
import { TextareaAutosizeModule } from 'ngx-textarea-autosize';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { UrlSerializer } from '@angular/router';
import { LowerCaseUrlSerializer } from './providers/url-serializer';
//import { YajnaNavComponent } from './components/header/yajna-nav.component';

@NgModule({
  declarations: [
      AppComponent,                   
        SitemapPage,
        FeedPage,
        HomePage,       
        TopnavComponent,
        HeaderComponent,     
        //YajnaNavComponent,  
        FooterComponent,        
    AdminMenuComponent    
    ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
        BrowserModule,
        AppRoutingModule,
        FlexLayoutModule,        
        BrowserAnimationsModule,
      HttpClientModule,
      NgxPaginationModule,
      //MatIconModule,
      //MatButtonModule,
      //MatSidenavModule,
      //MatToolbarModule,
      EmbedVideo.forRoot(),
      LoggerModule.forRoot({
        //serverLoggingUrl: '/api/logs',
        level: NgxLoggerLevel.TRACE,
        serverLogLevel: NgxLoggerLevel.ERROR,
        disableConsoleLogging: false
      }),
      TextareaAutosizeModule,
    FontAwesomeModule,
    NgbModule,
    NgxUsefulSwiperModule
    ],
  providers: [Title,
    {
      provide: UrlSerializer, useClass: LowerCaseUrlSerializer},
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    //{ provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }
  ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
