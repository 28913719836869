import { async } from 'rxjs';
import { environment } from '../../environments/environment';
import { MediaFolder } from './media.models';

export enum BaseEntityType {
  //Undefined = 0,
  Page = 1,
  Item = 2,  
  User = 3
}

export enum CultureCode {
  //Undefined = 0,
  English = 1,
  Telugu = 2,
  Hindi = 3
}

export class BaseEntity {
  id: string;
  rev: number;
  type: BaseEntityType;
  title: string;
  key: string;
  cultureCode: CultureCode;
  createdOn?: Date;
  createdBy?: string;
  updatedOn?: Date;
  updatedBy?: null;
  dateDeleted?: Date;
}

export class ImageIcons {
  summaryImage: string;
  contentImage: string;
  thumbImage: string;
}

export class Keywords {
  categories: string;
  tags: string;
}

export class PagedResult<T>{

  currentPage?: number;
  pageCount?: number;
  pageSize?: number;
  rowCount?: number;

  results?: T[];
}


export class ApiError {
  message: string;
  errors: {
    type: string;
    $values: ApiErrorDetails[];
  }
}

export class ApiErrorDetails {
  propertyName: string;
  errorMessage: string;
  errorCode: string;
}

export function enumSelector(enumDef) {
  const result =  Object.keys(enumDef)
    .filter(t => isNaN(<any>t) && t !== 'values')
    .map(key => ({ value: enumDef[key], text: key }))
    .sort((a, b) => a.text < b.text ? -1 : 1);
    
  return result;
}


export function getCategories() {
  const categories = [
    { name: "Yajna" },
    { name: "Veda" },
    { name: "Pollution" },
    { name: "Peace" },
    { name: "God" },
    { name: "Atom" },
    { name: "Universe" },
    { name: "Sun" },
    { name: "Moon" }   
  ];

  return categories;
}

export function trimS(str: string) {
  //console.log(str);
  if (str) {
    return str.trim();
  }
  return "";
}

export function trimN(str) {  
  if (isNaN(str)) {
    return 0;
  }
  return Number(str);
}

export function trimB(str: any) {
  if (typeof str === "boolean") {
    return str;
  }
  if (str && str !== null &&
    str.trim().toLowerCase() === "true") {
    return true;
  }
  return false;
}

export function trimD(str: string): Date {
  if (str && str !== "") {
    return new Date(str);
  }
  return null;
}

export function fixKey(str: string): string {

  if (!str) {
    return "";
  }

  let key = str.trim().toLowerCase().replace(/\s/g, '-').replace(/\?/g, '');
  //console.log(key);
  return key;
}

export function getAngularEditorConfig(height = '15rem', simpleEditor = true) {

  const angularEditorConfig = {
    editable: true,
    spellcheck: true,
    sanitize: true,
    toolbarPosition: 'top',    
    height: height,
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: '',
    defaultFontSize: '',
    toolbarHiddenButtons: [[],[]],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
    uploadUrl: '',
    uploadWithCredentials: false    
  };

  if (simpleEditor === true) {
    angularEditorConfig.toolbarHiddenButtons = [
      ['undo',
        'redo',
        'strikeThrough',
        'subscript',
        'superscript',
        'justifyCenter',
        'justifyRight',
        'insertOrderedList',
        'heading'],
      ['fontName',
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
         'insertImage',
        'insertVideo',
        'removeFormat',
        'insertHorizontalRule'
      ]];
  }
  else {
    angularEditorConfig.toolbarHiddenButtons = [[],
    ['fontName',
      'fontSize',
      'textColor',
      'backgroundColor',
      'customClasses',
      'insertImage',
      'insertVideo',
      'insertHorizontalRule'
    ]];
  }
  return angularEditorConfig;
}

export function getImageUrl(folder: any, fileName: string): string {

  if (fileName.includes(":")) {
    return fileName;
  }
  else {
    let folderName = "";
    if (isNaN(folder) === false) {
      folderName = folder.toString();
    }
    else {
      folderName = folder;
    }

    return environment.API_URL + "MediaDownloadApi/" + folderName + "/" + fileName.replace(".", "");
  }
}

export function getSummaryImageName(img: string, mediaFolder = MediaFolder.Image): string {

  let imgName = trimS(img);
  if (imgName === "") {
    imgName = "assets/images/vedas.jpg"
  } else {
    imgName = getImageUrl(mediaFolder, imgName);
  }

  //console.log(imgName);
  return imgName;
}

export function getSummaryImageAsBackgroundUrl(img: string, mediaFolder = MediaFolder.Image): string {

  let imgBackgroundUrl = trimS(img);
  if (imgBackgroundUrl === "") {
    imgBackgroundUrl = "assets/images/vedas.jpg"
  }
  else {
    imgBackgroundUrl = getImageUrl(mediaFolder, imgBackgroundUrl);
  }
  return "url('" + imgBackgroundUrl + "')";
}

export function getContentImageName(img: string, mediaFolder : MediaFolder = MediaFolder.Image): string {

  //console.log('folder: ' + mediaFolder);
  //console.log('img name: ' + img);

  let imgName = trimS(img);
  if (imgName === "") {
    imgName = "assets/images/the-four-vedas.jpg"
  }
  else {  
    imgName = getImageUrl(mediaFolder, imgName);
  }
  return imgName;
}

export async function downloadFile(folder: number, fileName: string) {
  const url = environment.API_URL + "MediaDownloadApi/" + folder.toString() + "/" + fileName.replace(".", "");
  window.open(url);
} 

export function getYouTubeVideoImageUrl(videoLink: string) {

  let imgLink = "";
  if (videoLink) {
    imgLink = trimS(videoLink);

    imgLink = imgLink.replace("youtu.be", "img.youtube.com/vi")

    imgLink = imgLink + "/hqdefault.jpg";
  }

  return "url('" + imgLink + "')";
}

export function getYouTubeVideoImageName(videoLink: string) {

  let imgLink = "";
  if (videoLink) {
    imgLink = trimS(videoLink);

    imgLink = imgLink.replace("youtu.be", "img.youtube.com/vi")

    imgLink = imgLink + "/hqdefault.jpg";
  }

  return imgLink;
}
