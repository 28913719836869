import { PagedResult, BaseEntity, ImageIcons, Keywords } from './shared.models';


export class PageGetCmdInput {
  pageId: string;
  key: string;
  draft: boolean;
}

export class PageGetCmdOutput {
  page: Page;
}

export enum PageType {
  //Undefined = 0,
  StaticPage = 1,
  Blog = 2,
  Research = 3,
  //Presentation = 4
}



export class Page extends BaseEntity {
  pageType: PageType;
  subTitle: string;
  summary: string;
  content: string;
  contentFile: string;
  layout: string;
  tlDr: string;
  images: ImageIcons;
  keywords: Keywords;
  authors: string;
  publishDate?: Date;
  active: boolean;
  averageReadTimeInMin: number;
  approved: boolean;
  approvedBy: string;
  approvedDate?: Date;
  relatedPages: Array<string>;
  allowComments: boolean;
  maxAllowedComments: number;
  maxAllowedRepliesPerComment: number;
  showInMenu: boolean;
  displayOrder: number;
  parentMenu: string;
}

export class PageDraft extends Page {
  draft: boolean;
  approved: boolean;
}

export class PageSaveCmdInput {
  page: Page;
  draft: boolean;
  approved: boolean;
}

export class PageSaveCmdOutput {
  saved: boolean;
  pageId: string;
  errorMsg: string;
}

export class PageListCmdInput {
  pageType: PageType;
  draft: boolean;
  forEdit: boolean;
  currentPage: number;
  pageSize: number;
}



export class PageListDto {
  pageId: string;
  pageType: PageType;
  title: string;
  key: string;  
  summary: string;
  summaryImage: string;
  averageReadTimeInMin: number;
  active: boolean;
  draft: boolean;
  createdOn?: Date;
  createdBy: string;
  updatedOn?: Date;
  updatedBy: string;
}

export class PageListCmdOutput {
  pages: PagedResult<PageListDto>;
}

export class PageDeleteCmdInput {
  pageId: string;
  draft: boolean;
}

export class PageDeleteCmdOutput {
  deleted: boolean;
  errorMsg: string;
}

