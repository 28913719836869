// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_URL_Local: "http://localhost:7071/api/",
  API_URL_Azure: "https://vedasworldfaasapi.azurewebsites.net/api/",
  API_URL: "https://vedasworldfaasapi.azurewebsites.net/api/",  
  API_TIME_OUT_IN_SEC: 30,
  ROWS_PER_PAGE: 12,
  AZURE_MAPS_KEY: 'yzOYKs6xAzecFmCFAxWrJUteP0gAY3I-kD9ZDxEye6s',
  STRIPE_TEST_PUBLIC_KEY: 'pk_test_51H7t8KAZ8c1R85eTMvPTWLXngyUM7mMaa1aKV6MjEecUHBf1HY3LqrXx7gXbKkIrWhIB0Q83DqxABHj83jYZjbU700Cbe0XfKN',
  STRIPE_PUBLIC_KEY: 'pk_live_51H7t8KAZ8c1R85eTGve0XdyGGZ7V5ioEQO3gGztJeSrb6hQ2EPOvmg7GGjoOUhembJoXO1QLdvW2uLIZVigi2ahN00ff7LoFXN'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
